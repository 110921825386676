import React from "react";
import { useState, useCallback } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../formCss.css";
import Email from './applicationComponents/Email'
import EmploymentStatus from './applicationComponents/EmploymentStatus'
import FirstTimeBuyer from './applicationComponents/FirstTimeBuyer'
import FullName from './applicationComponents/FullName'
import HouseholdIncome from './applicationComponents/HouseholdIncome'
import LoanType from './applicationComponents/LoanType'
import PhoneNumber from './applicationComponents/PhoneNumber'
import PropertyType from './applicationComponents/PropertyType'
import PurchasePrice from './applicationComponents/PurchasePrice'
import ZipCode from "./applicationComponents/ZipCode";
import Success from "./applicationComponents/Success"
import Error from "./applicationComponents/Error"
import { First } from "react-bootstrap/esm/PageItem";

const error = "Please enter a ";



export default function Apply(props) {
  // const [page, setPage] = useState(0)
  const [error, setError] = useState(false)

  const FormTitles = ["Loan Type", "Zip Code", "Property Type", "First Time Buyer?", "Purchase Price", "Total Household Income", "Employment Status", "Full Name", "Email", "Phone Number"]

  const PageDisplay = () => {
    if (props.page === 0) {
      return <LoanType
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 1) {
      return <ZipCode
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 2) {
      return <PropertyType
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 3) {
      return <FirstTimeBuyer
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 4) {
      return <PurchasePrice
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 5) {
      return <HouseholdIncome
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 6) {
      return <EmploymentStatus
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 7) {
      return <FullName
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 8) {
      return <Email
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 9) {
      return <PhoneNumber
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 10) {
      return <Success
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    } else if (props.page === 11) {
      return <Error
                mortgageInq={props.mortgageInq}
                handleChange={props.handleChange} />
    }
  }

  const handleNext = () => {
    if (props.page === 0 && props.mortgageInq.loanType === "") {
      setError(true)
      return
    } else if (props.page === 1 && props.mortgageInq.zip === "") {
      setError(true)
      return
    } else if (props.page === 2 && props.mortgageInq.propertyType === "") {
      setError(true)
      return
    } else if (props.page === 3 && props.mortgageInq.firstTimeBuyer === "") {
      setError(true)
      return
    } else if (props.page === 4 && props.mortgageInq.purchasePrice === "") {
      setError(true)
      return
    } else if (props.page === 5 && props.mortgageInq.houseHoldIncome === "") {
      setError(true)
      return
    } else if (props.page === 6 && props.mortgageInq.employmentStatus === "") {
      setError(true)
      return
    } else if (props.page === 7 && props.mortgageInq.fullName === "") {
      setError(true)
      return
    } else if (props.page === 8 && props.mortgageInq.email === "") {
      setError(true)
      return
    } else if (props.page === 9 && props.mortgageInq.phoneNumber === "") {
      setError(true)
      return
    } else {
      setError(false)
      props.setPage((currPage) => currPage + 1)
    }
  }

  const handleBack = () => {
    setError(false)
    props.setPage((currPage) => currPage - 1)
  }

  return (
    <div className="apply">
    <div className="form">
      <div className="progressbar">
        <div style={{width: props.page === 0 ? "10%" : props.page == 1 ? "20%" : props.page == 2 ? "30%" : props.page == 3 ? "40%" : props.page == 4 ? "50%" : props.page == 5 ? "60%" : props.page == 6 ? "70%" : props.page == 7 ? "80%" : props.page == 8 ? "90%" : "100%"}}></div>
      </div>
      <div className="form-container-apply">
        <div className="header">
          <label for={props.page}><h1>{FormTitles[props.page]}</h1></label>
        </div>
        <div className="body">{PageDisplay()}</div>
          {error ? <p className="errorText">Please complete the field!</p> : <p> </p>}
        <div className="footer">
          <div>

          {props.page == 0 || props.page > 9 ? null : <button
            onClick={handleBack}
            >Prev</button>}
          {props.page >= 9 ? null : <button
            disabled={props.page == FormTitles.length - 1} 
            onClick={handleNext}>Next</button>}
          {props.page === 9 ? <button onClick={props.handleAppSubmit}>Submit</button> : null}
            </div>
        </div>
      </div>
    </div>
    </div>
  );
}
