import "./App.css";
import "./index.css";
import React from "react";
import { useState } from "react";
import { send } from "emailjs-com";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import LoanOptions from "./components/LoanOptions";
import BuyAHome from "./components/BuyAHome";
import ErrorPage from "./components/ErrorPage";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Apply from "./components/Apply";
import Rates from "./components/Rates";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";

const keyOne = process.env.REACT_APP_LOAN_INQ_SUBMIT_ONE
const keyTwo = process.env.REACT_APP_LOAN_INQ_SUBMIT_TWO
const keyThree = process.env.REACT_APP_LOAN_INQ_SUBMIT_THREE 


function App() {
  const [page, setPage] = useState(0)
  const [statusBar, setStatusBar] = useState(0);
  const [mortgageInq, setMortgageInq] = useState({
    loanType: "",
    zip: "",
    propertyType: "",
    firstTimeBuyer: "",
    purchasePrice: "",
    houseHoldIncome: "",
    employmentStatus: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    textConsent: true
  });

  const handleSelect = (e) => {
    console.log(e.target.name, e.target.value);
    setMortgageInq({
      ...mortgageInq,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    console.log(mortgageInq);
    setMortgageInq({
      ...mortgageInq,
      [e.target.name]: e.target.value,
    });
  };

  const handleAppSubmit = (e) => {
    e.preventDefault();
    send(
      keyOne,
      keyTwo,
      mortgageInq,
      keyThree
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setPage(page + 1)
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setPage(page + 2)
      });
  };

  return (
    <Router>
      <div className="master">
      <Header />
      <div className="body-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/loanoptions" element={<LoanOptions />} />
          <Route
            path="/apply"
            element={
              <Apply
                page={page}
                setPage={setPage}
                handleAppSubmit={handleAppSubmit}
                mortgageInq={mortgageInq}
                setMortgageInq={setMortgageInq}
                handleChange={handleChange}
                handleSelect={handleSelect}
                statusBar={statusBar}
                setStatusBar={setStatusBar}
              />
            }
          />
          {/* <Route path="/rates" element={<Rates />} /> */}
          <Route path="/purchase" element={<BuyAHome />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/*" element={<ErrorPage />} />
          {/* </Route> */}
        </Routes>
        <Footer />
      </div>
      </div>
    </Router>
  );
}

export default App;
