import React from "react"
import '../../formCss.css'


const LoanType = (props) => {
  
  return (
    <div className="sign-up-container">
      <input 
        type="text"
        placeholder="Purchase? Refi?"
        name="loanType"
        value={props.mortgageInq.loanType}
        onChange={props.handleChange}
      />
    </div>
  )
};

export default LoanType;
