import React from "react"
import "../../formCss.css";

const Success = (props) => {
  return (
    <div className="sign-up-container">
      <p>Thank you! We will be in touch soon!</p>
    </div>
  )
};

export default Success;
