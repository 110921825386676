import React from "react"
import "../../formCss.css";

const EmploymentStatus = (props) => {
  return (
    <div className="sign-up-container">
      <input 
        type="text"
        placeholder="Employment Status"
        name="employmentStatus"
        value={props.mortgageInq.employmentStatus}
        onChange={props.handleChange}
      />
    </div>
  )
};

export default EmploymentStatus;
