import React from "react";
import logo from "../images/logo.png";
import house from "../images/house-silhouette-svgrepo-com.svg";
import { NavLink } from "react-router-dom";

export default function Header() {
  return (
    <div className="header-container">
      <div className="header-logo">
        <img
          src={logo}
          className="header-prmg-logo"
          alt="Paramount Residential Mortagage Group, INC."
        />
      </div>
      <div className="header-nav">
        <NavLink className="header-nav-links" to="/">
          Home
        </NavLink>
        {/* <NavLink className="header-nav-links" to="/loanoptions">
          Loan Options
        </NavLink> */}
        <NavLink className="header-nav-links" to="/apply">
          Apply Now
        </NavLink>
        {/* <NavLink className="header-nav-links" to="/purchase">
          Purchase
        </NavLink> */}
        <NavLink className="header-nav-links" to="/about">
          About
        </NavLink>
        <NavLink className="header-nav-links" to="/contact">
          Contact Us
        </NavLink>
      </div>
      <NavLink to="/" className="header-menu">
        {/* <img src={house} className="header-house-svg" alt="House SVG Icon" /> */}
        <h1 className="header-h1">Find Your Dream Home Today</h1>
      </NavLink>
    </div>
  );
}
