import React from "react";
import mortagehome from "../images/vinicius-amnx-amano-ALpEkP29Eys-unsplash.jpg";
import { useNavigate } from "react-router";
import { useState } from "react";
// import Modal from "./application/Modal";

// Icons
import InvestHouse from "../images/undraw_buy_house_re_8xq7.svg";
import InvestHouse2 from "../images/undraw_choosing_house_re_1rv7.svg";
import InvestHouse3 from "../images/undraw_house_searching_re_stk8.svg";
import InvestHouse4 from "../images/undraw_investing_re_bov7.svg";
import InvestHouse5 from "../images/undraw_investment_data_re_sh9x.svg";
import InvestHouse6 from "../images/undraw_select_house_re_s1j9.svg";
import InvestHouse7 from "../images/undraw_sweet_home_dkhr.svg";

export default function Home() {
  const [showModal, setShowModal] = useState(false);
  // const [mortgageInq, setMortgageInq] = useState({
  //   loanType: "",
  //   zip: "",
  //   propertyType: "",
  //   firstTimeBuyer: "",
  //   purchasePrice: "",
  //   houseHoldIncome: "",
  //   employmentStatus: "",
  //   fullName: "",
  //   email: "",
  //   phoneNumber: 0
  // })

  const navigate = useNavigate();

  const buttonNavLoanPrograms = (e) => {
    e.preventDefault();
    navigate("/loanoptions");
  };

  const applyNav = (e) => {
    e.preventDefault();
    navigate("/apply");
  };

  return (
    <>
      <div className="home-container">
        <div className="home-info">
          <h2>Ready to buy your dream home? Let us help!</h2>
          <p>
            <i class="gg-check"></i> Purchase
          </p>
          <p>
            <i class="gg-check"></i> Cash-Out Refinance
          </p>
          <p>
            <i class="gg-check"></i> Refinancing
          </p>
          <p>
            <i class="gg-check"></i> Debt Consolidation
          </p>

          <p>
            <i class="gg-check"></i> Reverse Mortgage
          </p>
          <button
            className="slide home-info-button"
            onClick={buttonNavLoanPrograms}
          >
            Loan Programs
          </button>
          <button className="slide home-info-button" onClick={applyNav}>
            Apply Now
          </button>
        </div>
        <div className="home-image">
          <img src={mortagehome} alt="Homepage image of a mortgage" />
        </div>
        {/* {showModal && 
        <Modal 
          mortgageInq={mortgageInq}
          setMortgageInq={setMortgageInq}
          handleChange={handleChange} 
          handleSelect={handleSelect}
          closeModal={closeModal}
          handleAppSubmit={handleAppSubmit}
        />
        } */}
      </div>
      <div className="container-loans">
        <h1>Flexible financing. For seasoned and first time home buyers.</h1>
        <ul>
          <div className="icons">
            <img
              src={InvestHouse}
              alt=" Conventional Programs as low as 3% down."
            />
            <li>
              Conventional Programs as low as <strong>3%</strong> down.
            </li>
          </div>
          <div className="icons">
            <img src={InvestHouse2} alt="FHA Programs as low as 3.5% down." />
            <li>
              FHA Programs as low as <strong>3.5%</strong> down.
            </li>
          </div>
          <div className="icons">
            <img
              src={InvestHouse3}
              alt="VA Financing with no required downpayment."
            />
            <li>VA Financing with no required downpayment.</li>
          </div>
          <div className="icons">
            <img src={InvestHouse4} alt="Jumbo Programs as low as 10% down." />
            <li>
              Jumbo Programs as low as <strong>10%</strong> down.
            </li>
          </div>
          <div className="icons">
            <img
              src={InvestHouse5}
              alt="Reverse Mortgages for both purchase as well as refinance."
            />
            <li>
            Reverse Mortgages for both purchase as well as refinance.
            </li>
          </div>
          <div className="icons">
            <img
              src={InvestHouse6}
              alt="USDA financing with no required down payment."
            />
            <li>USDA financing with no required down payment.</li>
          </div>
          <div className="icons">
            <img
              src={InvestHouse7}
              alt="We have all kinds refinancing to meet your needs"
            />
            <li>We have all kinds refinancing to meet your needs.</li>
          </div>
        </ul>
      </div>
    </>
  );
}
