import React from "react";
import logo from "../images/logo.png";
import { useNavigate } from "react-router";
import Facebook from '../images/fb.jpg'
import Insta from '../images/ig.jpg'
import Linked from '../images/linked.jpg'
import X from '../images/x.png'
import Yt from '../images/yt.svg'


export default function Footer() {
  const navigate = useNavigate();

  const contactNav = (e) => {
    e.preventDefault();
    navigate("/contact");
  };


  return (
    <>
      <footer className="footer-container">
        <div className="footer-logo">
          <img src={logo} alt="footer logo" />
          <h1 onClick={contactNav}>Need help? Contact us.</h1>
        </div>
        <div className="footer-info">
          <div className="footer-block">
            <h4>Location</h4>
            <p>
              3 Regency Plaza, Ste 23
              <br />
              Providence, Rhode Island <br />
              02903
            </p>

            <p>(401) 639-4600</p>
            <p>jgibbons@prmg.net</p>
            <p>Monday - Friday 9a - 6p</p>

            <p>Licensed in RI, MA, CT, FL</p>
            <p>PRMG is licensed to lend in 49 states.</p>
          </div>
          {/* <div className="footer-block"> */}
            {/* <h4>Discover</h4> */}
            {/* <p>Loan Options</p>
            <p>Refinance</p>
            <p>Rates</p>
            <p>Purchase</p> */}
          {/* </div> */}
          <div className="footer-block">
            <h4>About us</h4>
            {/* <p>Mission</p>
            <p>Our Team</p>
            <p>Careers</p> */}
            <a href="https://x.com/JohnGibbonsMtg">
              <img alt="X" className="logos" src={X} />
            </a>
            <a href="https://www.facebook.com/JGibbonsTheMortgageGuy/">
              <img alt="fb" className="logos" src={Facebook} />
            </a>
            <a href="https://www.instagram.com/jgibbonsthemortgageguy/">
              <img alt="Instagram" className="logos" src={Insta} />
            </a>
            <a href="https://www.linkedin.com/in/john-gibbons-prmg/">
              <img alt="LinkedIn" className="logos" src={Linked} />
            </a>
            <a href="https://www.youtube.com/channel/UCv2Q5whpxGK-87Uia7D1JZg">
              <img alt="YouTube" className="logos" src={Yt} />
            </a>
            <a  href="https://www.experience.com/reviews/john-gibbons-iii"><p className="testimonials">Testimonials</p></a>
            
          </div>
        </div>
      </footer>
      <div className="footer-disclosures">
        <a
          href="  https://www.prmg.net/licenses/"
          aria-label="Visit Disclosures page"
        >
          Licensing and other disclosures
        </a>
        <a href="https://www.prmg.net/licenses/">Licensing information</a>
        <a href="#" aria-label="Visit Your Privacy Choices page">
          Your Privacy Choices
        </a>
        {/* <a href="/terms-of-service" aria-label="Visit Terms of Service page">
          Terms of Service
        </a>
        <a href="/terms-of-service" aria-label="Visit Terms of Service page">
          Cookies
        </a> */}
        <a href="mailto:tgibbons@PRMG.net">
          Have questions or comments? email us!
        </a>
      </div>
      <div className="footer-copyright">
        <p>&copy; 2023 Paramount Residential Mortgage Group, Inc.</p>
        <p> PRMG is an Equal Housing Lender. </p>
        <p>(“PRMG”) NMLS ID #75243</p>
        <p>1265 Corona Pointe Court, Corona, CA 92879</p>
        <p>
          Licensed by The Department of Financial Protection and Innovation
          under the California Residential Mortgage Lending Act, License
          #4131268
        </p>

        <p>Finance Lenders Law License #603D903.</p>
        <p>AZ Mortgage Banker License #910387.</p>
        <p> Georgia Residential Mortgage Licensee #32087.</p>
        <p> IL Residential Mortgage License # MB.6760962.</p>
        <p>KS-Licensed Mortgage Company, #MC.0025196.</p>
        <p>Massachusetts Mortgage Lender and Broker License, #MC75243.</p>
        <p>MS Department of Bank and Consumer Finance</p>
        <p>NV Mortgage Broker License #3693.</p>
        <p>NH Banking Department 17393-MB.</p>
        <p>Dept. of Banking in the Common Wealth of PA, #37894.</p>
        <p>RI Licensed Lender, #20112799LL.</p>
        <p>
          Approved to lend in the following states AL, AK, AR, CO, CT, DE, DC,
          FL, HI, ID, IA, KY, LA, ME, MD, MI, MN, MO, MT, NJ, NM, NC, ND, OH,
          OK, OR, SC, SD, TN, TX, UT, VT, WA, WV, WI, WY.
        </p>
        <p>All Rights Reserved.</p>
      </div>
    </>
  );
}
