import React from "react";
import headshot from "../images/headshot.png";
import "../App.css";

export default function About() {
  return (
    <div className="about-container">
      <div className="about-pic">
        <img
          alt="John Gibbons"
          src={headshot}
          className="about-page-headshot"
        />
        <p className="name">John Gibbons III</p>
        <p className="title">Branch Manager</p>
        <p className="email">
          <a href="mailto:JGibbonsiii@prmg.net">JGibbons@prmg.net</a>
        </p>
        <p className="phone">
          <a href="tel:+1 401-639-4600">401-639-4600</a>
        </p>
        <p className="license">
          <strong>Licensed in CT</strong> | <strong>MA</strong> |{" "}
          <strong>RI | FL NMLS#</strong> 19893
        </p>
      </div>
      <div className="about-bio">
        <h2 className="slogan">
          Let me help you navigate your way to Homeownership!
        </h2>
        <p className="about-paragraph">
          John Gibbons is a Certified Public Accountant who began his career
          with KPMG, one of the current "Big 4" International Accounting Firms.
          After public accounting, he became a controller for two very large
          construction companies in New England. In 1996 he founded a RI ISP
          (Internet Service Provider) and also owned and operated a large
          cellular phone distributorship from 1997-2005. Since 2005, he’s turned
          his focus to the mortgage industry, where he has been able to utilize
          his many years of finance and accounting skills to analyze clients’
          unique financial situations and determine which most advantageous
          mortgage programs they would benefit from.
        </p>
      </div>
    </div>
  );
}
