import React from "react";
import InvestHouse from "../images/undraw_buy_house_re_8xq7.svg";
import InvestHouse2 from "../images/undraw_choosing_house_re_1rv7.svg";
import InvestHouse3 from "../images/undraw_house_searching_re_stk8.svg";
import InvestHouse4 from "../images/undraw_investing_re_bov7.svg";
import InvestHouse5 from "../images/undraw_investment_data_re_sh9x.svg";
import InvestHouse6 from "../images/undraw_select_house_re_s1j9.svg";
import InvestHouse7 from "../images/undraw_sweet_home_dkhr.svg";

export default function LoanOptions() {
  return (
    <div className="container-loans">
      <h1>Flexible financing. For seasoned and first time home buyers.</h1>
      <ul>
        <div className="icons">
          <img src={InvestHouse} alt="house invest" />
          <li> Conventional Programs as low as <strong>3%</strong> down.</li>
        </div>
        <div className="icons">
          <img src={InvestHouse2} alt="house invest" />
          <li>FHA Programs as low as <strong>3.5%</strong> down.</li>
        </div>
        <div className="icons">
          <img src={InvestHouse3} alt="house invest" />
          <li>VA Financing with no required downpayment.</li>
        </div>
        <div className="icons">
          <img src={InvestHouse4} alt="house invest" />
          <li>Jumbo Programs as low as <strong>10%</strong> down.</li>
        </div>
        <div className="icons">
          <img src={InvestHouse5} alt="house invest" />
          <li>Reverse Mortgages purchase options.</li>
        </div>
        <div className="icons">
          <img src={InvestHouse6} alt="house invest" />
          <li>USDA financing with no required down payment.</li>
        </div>
        <div className="icons">
          <img src={InvestHouse7} alt="house invest" />
          <li>Discover which mortgage option is best to fit your needs.</li>
        </div>
      </ul>
    </div>
  );
}
