import React from "react"
import "../../formCss.css";

const PurchasePrice = (props) => {
  return (
    <div className="sign-up-container">
      <input 
        type="text"
        placeholder="Purchase Price"
        name="purchasePrice"
        value={props.mortgageInq.purchasePrice}
        onChange={props.handleChange}
      />
    </div>
  )
};

export default PurchasePrice;
