import React from "react"
import "../../formCss.css";

const PropertyType = (props) => {
  return (
    <div className="sign-up-container">
      <input 
        type="text"
        placeholder="Property Type"
        value={props.mortgageInq.propertyType}
        name="propertyType"
        onChange={props.handleChange}
      />
    </div>
  )
};

export default PropertyType;
