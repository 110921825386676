import React from "react"
import "../../formCss.css";

const Error = (props) => {
  return (
    <div className="sign-up-container">
      <p>Error! Please try again!</p>
    </div>
  )
};

export default Error;
