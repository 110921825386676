import React from "react"
import "../../formCss.css";
import { Checkbox, FormGroup, FormControlLabel, createStyles, Typography } from "@mui/material";


const PhoneNumber = (props) => {

  const handleConsent = (e) => {
    e.preventDefault();
    console.log(props.formData.textConsent)
    props.setFormData({
      ...props.formData,
      textConsent: e.target.checked
  })
}
const styles = createStyles({
  formControlLabel: { fontSize: '14px', color: 'white', textAlign: 'center'
  }
})

  return (
    <div className="sign-up-container">
      <input 
        type="text"
        placeholder="Phone Number"
        name="phoneNumber"
        value={props.mortgageInq.phoneNumber}
        onChange={props.handleChange}
      />
      <FormGroup>
            <FormControlLabel 
              control={
              <Checkbox
                sx={{ 
                  paddingLeft: "5%"
              }}
                checked={props.mortgageInq.textConsent} 
                onChange={handleConsent} 
              />}
              label={<Typography className={styles.formControlLabel}>Do you consent to be contacted via Text Message?</Typography>} 
            />
          </FormGroup>
    </div>
  )
};

export default PhoneNumber;
